enum BaseRouteNames {
  PickTenant = 'PickTenant',
}

export enum UnauthenticatedRouteNames {
  Login = 'Login',
  ResetPassword = 'ResetPassword',
  SetPassword = 'SetPassword',
  SignUp = 'SignUp',
}

export enum TenantRouteNames {
  Details = 'CompanyDetails',
  TenantTrades = 'TenantTrades',
  TenantTradeSequences = 'TenantTradeSequences',
  ProjectStructureTemplates = 'ProjectStructureTemplates',
}

export enum UserRouteNames {
  Edit = 'UserEdit',
  NoMemberships = 'NoMemberships',
}

export enum LeanRouteNames {
  All = 'LeanProjectsAll',
  Dashboard = 'LeanDashboard',
  Participants = 'Participants',
  Subcontractors = 'Subcontractors',
  Memberships = 'Memberships',
  Schedule = 'LeanProjectSchedule',
  Tickets = 'Tickets',
  PhotoDocumentation = 'PhotoDocumentation',
}

export const Routes = {
  Unauthenticated: UnauthenticatedRouteNames,
  User: UserRouteNames,
  Tenant: TenantRouteNames,
  Base: BaseRouteNames,
  Lean: LeanRouteNames,
};

export const useRouteNames = () => ({ Routes });
