import { AnalyticsEvent, AnalyticsEventCategories } from '@/utils/analyticsEvents/categories';

export class ApplicationOpenedEvent extends AnalyticsEvent {
  public readonly identification = AnalyticsEventCategories.APPLICATION_OPENED;

  public constructor() {
    super();
  }
}

export class TriedToLeaveWithUnacknowledgedEventsEvent extends AnalyticsEvent {
  public readonly identification =
    AnalyticsEventCategories.TRIED_TO_LEAVE_WITH_UNACKNOWLEDGED_EVENTS;

  public constructor() {
    super();
  }
}

export class ProjectsSwitchedEvent extends AnalyticsEvent {
  public readonly identification = AnalyticsEventCategories.PROJECTS_SWITCHED;

  public constructor() {
    super();
  }
}

export class ConflictNotificationShownEvent extends AnalyticsEvent {
  public readonly identification = AnalyticsEventCategories.CONFLICT_NOTIFICATION_SHOWN;

  public constructor(
    public properties: {
      isUndoRedoConflict: boolean;
    },
  ) {
    super();
  }
}

export class ConnectionStateChangedEvent extends AnalyticsEvent {
  public readonly identification = AnalyticsEventCategories.CONNECTION_STATE_CHANGED;

  public constructor(
    public properties: {
      newState: 'online' | 'offline';
    },
  ) {
    super();
  }
}

export class TechnicalErrorNotificationShownEvent extends AnalyticsEvent {
  public readonly identification = AnalyticsEventCategories.TECHNICAL_ERROR_NOTIFICATION_SHOWN;

  public constructor(
    public properties: {
      type: 'restore_failed' | 'invalid_local_event_order';
    },
  ) {
    super();
  }
}

export class ScheduleUserAvatarHoveredEvent extends AnalyticsEvent {
  public readonly identification = AnalyticsEventCategories.SCHEDULE_USER_AVATAR_HOVERED;

  public constructor(
    public properties: {
      avatarType: 'own' | 'other' | 'more';
    },
  ) {
    super();
  }
}
