import { Composer } from 'vue-i18n';

import { isMacOS } from '@/helpers/utils/os';
import { LoggingService } from '@/interfaces/services';
import Button from '@/jsx/components/Button';
import Tooltip from '@/jsx/components/Tooltip';
import { h } from '@/jsx/jsxFactory';
import { getScheduler } from '@/services/store/integrations/scheduler';

type JumpToTodayButtonProps = {
  i18n: Composer;
  loggingService: LoggingService;
};

const SHORTCUT_KEY = isMacOS() ? 'keyboard.mac.jumpToToday' : 'keyboard.rest.jumpToToday';

export default function JumpToTodayButton({ i18n, loggingService }: JumpToTodayButtonProps) {
  const onClick = (event: MouseEvent) => {
    const scheduler = getScheduler();
    scheduler?.scrollToNow({ block: 'center' });
    loggingService.trackEvent(
      new loggingService.AnalyticEventCategories.ScheduleJumpToTodayClickedEvent({
        method: 'click',
      }),
    );
    if (event.target) {
      (event.target as HTMLElement).blur();
    }
  };

  return (
    <div
      id="jump-to-today-button"
      class="show-schedule-subheader tw-flex tw-flex-grow tw-justify-center tw-items-center"
    >
      <div class="tw-relative tw-flex tw-justify-center">
        <Tooltip
          position="bottom"
          disabled={false}
          text={i18n.t('objects.leanProject.schedule.jumpToTodayTooltip')}
          shortcut={i18n.t(SHORTCUT_KEY)}
        >
          <Button
            className="tw-min-w-[120px]"
            clickAction={onClick}
            type="button"
            hierarchy="secondary"
            size="lg"
          >
            {i18n.t('objects.leanProject.schedule.jumpToToday')}
          </Button>
        </Tooltip>
      </div>
    </div>
  );
}
