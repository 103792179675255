import {
  Callback,
  ProjectSubcontractorChangePayload,
  ProjectSubscriptionCallback,
  ProjectSubscriptionEvent,
  ProjectTradeChangePayload,
  ProjectTradeReplacementPayload,
  ProjectUnsubscribeEvent,
  RTCSocketActions as GeneratedRTCSocketActions,
} from 'events.schema';
import { debounce } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { io, Socket } from 'socket.io-client';

import { useIsOnline } from '@/common/composables/useIsOnline';
import { getRandomId } from '@/helpers/utils/strings';
import { AuthenticationService, LoggingService } from '@/interfaces/services';
import { ConsoleLoggingService } from '@/services';
import { IS_PROD_ENV } from '@/utils/config';

import { useLiveUsers } from '../components/liveUsers/useLiveUsers';
import {
  RTC_EVENT_EMIT_TIMEOUT,
  RTC_LIVE_USERS_DEBOUNCE_TIME,
  RTC_REPLAY_UNACKNOWLEDGED_EVENTS_INTERVAL,
} from '../const';
import { LiveUser, LocalProjectChangeEvent, LocalProjectChangeEventTemplate } from '../types';
import { DefaultRTCMessageParser, RTCMessageParser } from './messageParser';
import {
  ExternalProjectDataChangeListener,
  ProjectChangeEventListener,
  RTCClientConnectionState,
  RTCClientResponseStatus,
  RTCSocketActions,
} from './types';
import { getConnectionWaitInterval, getJitteredTimeout } from './utils';

export class RTCClient {
  private socket: Socket;

  private clientId: string | undefined;

  private projectId: string | undefined;

  private stateId: number | undefined;

  private clientStateId: number | undefined;

  private connectionState = ref(RTCClientConnectionState.IDLE);

  private connectionStateSubject = new BehaviorSubject<RTCClientConnectionState>(
    RTCClientConnectionState.IDLE,
  );

  private projectChangeEventListeners: ProjectChangeEventListener[] = [];

  private externalProjectDataChangeListeners: ExternalProjectDataChangeListener[] = [];

  private connectionAttempts: number = 0;

  private connectionTimeoutId: number | undefined;

  private connectionWaitInterval: Ref<number> = ref(0);

  private unacknowledgedEvents: Map<
    string,
    { event: LocalProjectChangeEvent; onPublished?: () => void }
  > = new Map();

  private eventTimestamps: Map<string, number> = new Map();

  private connectedBefore = false;

  private transport: Ref<'websocket' | 'polling' | null> = ref(null);

  private replayEventsInterval: number | undefined;

  public constructor(
    private authenticationService: AuthenticationService,
    private endpoint: string,
    private messageParser: RTCMessageParser = new DefaultRTCMessageParser(),
    private loggingService: LoggingService = new ConsoleLoggingService(),
  ) {
    this.clientId = getRandomId();
    this.socket = this.createSocket();
    this.setupOnlineListener();
    this.setupSocketListeners();
    this.setupStatusSubscription();
  }

  private setupOnlineListener(): void {
    watch(useIsOnline(), (isOnline) => {
      if (isOnline) {
        this.startConnectionAttempts();
      } else {
        this.stopConnectionAttempts();
      }
    });
  }

  private setupStatusSubscription(): void {
    this.connectionStateSubject.subscribe((state) => {
      this.connectionState.value = state;
      if (state === RTCClientConnectionState.CONNECTED) {
        this.transport.value = this.socket.io.engine.transport.name as 'websocket' | 'polling';
        this.stopConnectionAttempts();
        this.attemptProjectResubscription();
      } else if (
        state === RTCClientConnectionState.DISCONNECTED ||
        state === RTCClientConnectionState.ERROR
      ) {
        this.startConnectionAttempts();
        this.transport.value = null;
        window.clearInterval(this.replayEventsInterval);
      }
    });
  }

  private clearConnectionTimeout(): void {
    if (this.connectionTimeoutId) {
      window.clearTimeout(this.connectionTimeoutId);
      this.connectionTimeoutId = undefined;
    }
  }

  private stopConnectionAttempts(): void {
    this.clearConnectionTimeout();
    this.connectionWaitInterval.value = 0;
    this.connectionAttempts = 0;
  }

  private createSocket(): Socket {
    return io(this.endpoint, {
      autoConnect: false,
      reconnection: false,
      query: {
        clientId: this.clientId,
      },
      secure: true,
      // Enable for load balancer cookies
      withCredentials: true,
      transports: ['websocket', 'polling'],
      auth: async (cb) => {
        // "auth" is called when executing socket.connect()

        const token = await this.authenticationService.getIdToken();
        if (!token)
          throw new Error('Auth token not found. Cannot continue with socket connection.');

        cb({ token: `JWT ${token}` });
      },
    });
  }

  public connect(): void {
    this.startConnectionAttempts();
  }

  private setupSocketListeners(): void {
    this.socket.on(
      GeneratedRTCSocketActions.ServerClientSocketActions.ProjectDataChanges.Subcontractors,
      (payload: ProjectSubcontractorChangePayload) => {
        if (!IS_PROD_ENV) {
          // eslint-disable-next-line no-console
          console.log('Subcontractors changed', payload);
        }
        const previousTimestamp = this.eventTimestamps.get(
          GeneratedRTCSocketActions.ServerClientSocketActions.ProjectDataChanges.Subcontractors,
        );
        if (!previousTimestamp || payload.timestamp > previousTimestamp) {
          this.externalProjectDataChangeListeners.forEach((listener) =>
            listener.callback({ name: 'SubcontractorChange', data: payload }),
          );
          this.eventTimestamps.set(
            GeneratedRTCSocketActions.ServerClientSocketActions.ProjectDataChanges.Subcontractors,
            payload.timestamp,
          );
        }
      },
    );

    this.socket.on(
      GeneratedRTCSocketActions.ServerClientSocketActions.ProjectDataChanges.Trades,
      (payload: ProjectTradeChangePayload) => {
        if (!IS_PROD_ENV) {
          // eslint-disable-next-line no-console
          console.log('Trades changed', payload);
        }
        const previousTimestamp = this.eventTimestamps.get(
          GeneratedRTCSocketActions.ServerClientSocketActions.ProjectDataChanges.Trades,
        );
        if (!previousTimestamp || payload.timestamp > previousTimestamp) {
          this.externalProjectDataChangeListeners.forEach((listener) =>
            listener.callback({ name: 'TradeChange', data: payload }),
          );
          this.eventTimestamps.set(
            GeneratedRTCSocketActions.ServerClientSocketActions.ProjectDataChanges.Trades,
            payload.timestamp,
          );
        }
      },
    );

    this.socket.on(
      GeneratedRTCSocketActions.ServerClientSocketActions.ProjectDataChanges.TradeReplacement,
      (payload: ProjectTradeReplacementPayload) => {
        if (!IS_PROD_ENV) {
          // eslint-disable-next-line no-console
          console.log('Trade was replaced', payload);
        }
        this.externalProjectDataChangeListeners.forEach((listener) =>
          listener.callback({ name: 'TradeReplacement', data: payload }),
        );
      },
    );

    this.socket.on(
      GeneratedRTCSocketActions.ServerClientSocketActions.LiveUsersChanged,
      debounce(
        (payload: { users: LiveUser[] }) => {
          const { setLiveUsers } = useLiveUsers();
          setLiveUsers(payload?.users);
        },
        RTC_LIVE_USERS_DEBOUNCE_TIME,
        { leading: true },
      ),
    );

    this.socket.on(RTCSocketActions.ServerToClientActions.Connect, () => {
      this.connectedBefore = true;
      if (!IS_PROD_ENV) {
        // eslint-disable-next-line no-console
        console.log('Connected to RTC, transport:', this.socket.io.engine.transport.name);
      }
      this.connectionStateSubject.next(RTCClientConnectionState.CONNECTED);
    });

    this.socket.on(RTCSocketActions.ServerToClientActions.ConnectError, (error) => {
      // Code set by backend
      // @ts-expect-error-next-line
      const errorCode = error?.data?.code;
      const isNetworkError = errorCode !== 'WS_CONNECTION_ERROR';
      if (!this.connectedBefore && isNetworkError) {
        // https://socket.io/docs/v3/client-initialization/#low-level-engine-options
        // Revert to classic upgrade, websocket upgrade will be attempted after connection was established
        this.socket.io.opts.transports = ['polling', 'websocket'];
        // always log this to console so support can easily verify behavior for customers
        // eslint-disable-next-line no-console
        console.log('change to polling approach');
      }
      this.loggingService.error(error, { code: 'RTCClientConnectError' });
      this.connectionStateSubject.next(RTCClientConnectionState.ERROR);
    });

    this.socket.on(RTCSocketActions.ServerToClientActions.Disconnect, () => {
      if (!IS_PROD_ENV) {
        // eslint-disable-next-line no-console
        console.log('Disconnected from websocket');
      }
      this.connectionStateSubject.next(RTCClientConnectionState.DISCONNECTED);
    });

    this.socket.on(RTCSocketActions.ServerToClientActions.ProjectChanged, (payload) => {
      if (!this.projectId) return;
      const event = this.messageParser.parseIncoming(payload);
      if (!IS_PROD_ENV) {
        // eslint-disable-next-line no-console
        console.log('Incoming event', event);
      }
      const isOwn = event.clientId === this.clientId;
      if (event.stateId != null) {
        if (
          event.status === RTCClientResponseStatus.SUCCESS &&
          this.stateId !== undefined &&
          event.stateId < this.stateId
        ) {
          this.loggingService.error(
            new Error(
              `Outdated stateId for event ${event.messageId} (Received: ${event.stateId}, Latest: ${this.stateId})`,
            ),
            {
              code: 'RTCClientStateIdError',
            },
          );
        }
        this.stateId = event.stateId;
      }

      // Project change event emits are timed out, however, it may happen that
      // the server still acknowledges the event, even after the timeout, which is why we need to also
      // remove the event from the unacknowledged events list here.
      const unacknowledgedEvent = this.unacknowledgedEvents.get(event.messageId);
      if (unacknowledgedEvent) {
        unacknowledgedEvent.onPublished?.();
        this.unacknowledgedEvents.delete(event.messageId);
      }

      this.projectChangeEventListeners.forEach((listener) => listener.callback(event, isOwn));
    });
  }

  private attemptProjectResubscription(): void {
    if (this.projectId) {
      this.subscribeToProject(this.projectId).catch((error) => {
        this.loggingService.error(error, { code: 'RTCClientResubscribeError' });
        this.socket.disconnect();
      });
    }
  }

  private async startConnectionAttempts(): Promise<void> {
    const isOnline = useIsOnline();

    if (
      isOnline.value &&
      this.connectionState.value !== RTCClientConnectionState.CONNECTED &&
      this.connectionState.value !== RTCClientConnectionState.CONNECTING
    ) {
      if (!IS_PROD_ENV) {
        // eslint-disable-next-line no-console
        console.log('Trying to connect...');
      }
      await this.authenticationService.waitForAuthentication();

      const waitInterval = getConnectionWaitInterval(this.connectionAttempts);
      this.connectionWaitInterval.value = waitInterval;

      this.clearConnectionTimeout();
      this.connectionTimeoutId = window.setTimeout(() => {
        this.connectionAttempts++;

        this.socket.connect();
      }, waitInterval);
    }
  }

  public registerProjectChangeEventListener(listener: ProjectChangeEventListener) {
    if (this.projectChangeEventListeners.some((l) => l.id === listener.id)) return;
    this.projectChangeEventListeners.push(listener);
  }

  public registerExternalProjectDataChangeListener(listener: ExternalProjectDataChangeListener) {
    if (this.externalProjectDataChangeListeners.some((l) => l.id === listener.id)) return;
    this.externalProjectDataChangeListeners.push(listener);
  }

  public prepareProjectChangeEventForPublishing(
    template: LocalProjectChangeEventTemplate,
  ): LocalProjectChangeEvent {
    if (!this.projectId) throw new Error('Cannot prepare change event without defined project id.');

    this.clientStateId = this.clientStateId !== undefined ? this.clientStateId + 1 : 0;
    return {
      ...template,
      clientTimestampMs: new Date().getTime(),
      stateId: this.stateId ?? 0,
      clientStateId: this.clientStateId,
      projectId: this.projectId,
    };
  }

  public publishProjectChangeEvent(
    event: LocalProjectChangeEvent,
    onPublished?: () => void,
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!useIsOnline().value) {
        if (!this.unacknowledgedEvents.has(event.messageId)) {
          this.unacknowledgedEvents.set(event.messageId, { event, onPublished });
        }
        resolve();
        return;
      }

      const onProjectChange = (timeoutError: Error | undefined, response: Callback) => {
        if (timeoutError) {
          if (!IS_PROD_ENV) {
            // eslint-disable-next-line no-console
            console.log('Event Timed Out', event);
          }
          this.unacknowledgedEvents.set(event.messageId, { event, onPublished });
          resolve();
          return;
        }

        this.unacknowledgedEvents.delete(event.messageId);
        if (response.status === RTCClientResponseStatus.ERROR) {
          this.loggingService.error(new Error(response.error.reason), {
            code: response.error.errorCode,
          });
          reject(response.error);
        } else if (response.status === RTCClientResponseStatus.SUCCESS) {
          resolve();
          onPublished?.();
        }
      };

      if (!IS_PROD_ENV) {
        // eslint-disable-next-line no-console
        console.log('Outgoing Event', event);
      }

      this.socket
        .timeout(RTC_EVENT_EMIT_TIMEOUT)
        .emit(
          RTCSocketActions.ClientToServerActions.ProjectChange,
          this.messageParser.parseOutgoing(event),
          onProjectChange,
        );
    });
  }

  public unsubscribeFromProject(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!this.projectId) {
        resolve();
        return;
      }

      const payload: ProjectUnsubscribeEvent = {
        projectId: this.projectId,
      };

      const onUnsubscribe = (response: Callback) => {
        if (response.status === RTCClientResponseStatus.ERROR) {
          this.loggingService.error(new Error(response.error.reason), {
            code: response.error.errorCode,
          });
          reject(response.error);
        } else if (response.status === RTCClientResponseStatus.SUCCESS) {
          if (!IS_PROD_ENV) {
            // eslint-disable-next-line no-console
            console.log('Unsubscribed from project', response);
          }
          this.resetProjectState();
          resolve();
        }
      };

      this.socket.emit(
        RTCSocketActions.ClientToServerActions.UnsubscribeProject,
        payload,
        onUnsubscribe,
      );
    });
  }

  public subscribeToProject(projectId: string): Promise<void> {
    if (!projectId) throw new Error('Cannot subscribe to project without a project ID.');

    if (!IS_PROD_ENV) {
      // eslint-disable-next-line no-console
      console.log('Trying to subscribe...');
    }

    return new Promise((resolve, reject) => {
      const clientState =
        this.clientStateId === undefined || this.stateId === undefined
          ? null
          : {
              earliestUnprocessedStateId: this.clientStateId!,
              lastReceivedStateId: this.stateId!,
            };

      const payload: ProjectSubscriptionEvent = {
        projectId,
        clientState,
      };

      const onSubscribe = async (response: ProjectSubscriptionCallback) => {
        if (response.status === RTCClientResponseStatus.ERROR) {
          this.loggingService.error(new Error(response.error.reason), {
            code: response.error.errorCode,
          });
          reject(response.error);
        } else if (response.status === RTCClientResponseStatus.SUCCESS) {
          if (!IS_PROD_ENV) {
            // eslint-disable-next-line no-console
            console.log('Subscribed to project', response);
          }
          this.stateId = response.stateId;
          this.clientStateId = response.stateId;
          this.projectId = projectId;

          this.replayUnacknowledgedEvents();
          this.replayEventsInterval = window.setInterval(() => {
            this.replayUnacknowledgedEvents();
          }, getJitteredTimeout(RTC_REPLAY_UNACKNOWLEDGED_EVENTS_INTERVAL));

          resolve();
        }
      };

      this.socket.emit(
        RTCSocketActions.ClientToServerActions.SubscribeProject,
        payload,
        onSubscribe,
      );
    });
  }

  private async replayUnacknowledgedEvents(): Promise<void> {
    const unacknowledgedEvents = Array.from(this.unacknowledgedEvents.values());
    for (let i = 0; i < unacknowledgedEvents.length; i++) {
      const unacknowledgedEvent = unacknowledgedEvents[i];
      await this.publishProjectChangeEvent(
        unacknowledgedEvent.event,
        unacknowledgedEvent.onPublished,
      );
    }
  }

  private resetProjectState(): void {
    this.projectId = '';
    this.stateId = undefined;
    this.clientStateId = undefined;
    this.projectChangeEventListeners = [];
    this.unacknowledgedEvents = new Map();
    useLiveUsers().setLiveUsers([]);
  }

  public get isConnected(): ComputedRef<boolean> {
    return computed(() => this.connectionState.value === RTCClientConnectionState.CONNECTED);
  }

  public get isPollingTransport(): ComputedRef<boolean> {
    return computed(() => this.transport.value === 'polling');
  }

  public get connectionIntervalMs(): ComputedRef<number> {
    return computed(() => this.connectionWaitInterval.value);
  }

  public get unacknowledgedEventsList(): LocalProjectChangeEvent[] {
    return Array.from(this.unacknowledgedEvents.values()).map((e) => e.event);
  }

  public get hasUnacknowledgedEvents(): boolean {
    return this.unacknowledgedEvents.size > 0;
  }

  public getClientId(): string {
    return this.clientId ?? '';
  }

  public disconnect(): void {
    this.socket.disconnect();
  }
}
