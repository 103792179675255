import {
  CalendarIntervalModel,
  CalendarModel,
  Column,
  ResourceTimeRangeModel,
  Row,
  SchedulerDependencyModel,
  SchedulerEventModel,
  SchedulerPro,
  SchedulerResourceModel,
} from '@bryntum/schedulerpro';

import { MilestoneType } from '@/features/milestones/types';
import { DryingBreak } from '@/graphql/__generated__/graphql';
import { NodeName } from '@/repositories/utils/cache';

export interface BaseSchedulerInterval {
  startDate: Date;
  endDate: Date;
  resourceId?: string;
  isFixed?: boolean;
}

export interface SchedulerEventAppearance {
  cls: string[];
  eventColor: string;
}

export enum DurationUnit {
  MILLISECOND = 'millisecond',
  SECOND = 'second',
  MINUTE = 'minute',
  HOUR = 'hour',
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  QUARTER = 'quarter',
  YEAR = 'year',
}

export interface SchedulerEvent
  extends Omit<Partial<SchedulerEventModel>, 'resourceId' | 'calendar' | 'eventColor'> {
  id: string;
  calendarId?: string | null;
  name: string;
  startDate: Date;
  endDate: Date;
  durationUnit: DurationUnit;
  duration: number;
  resourceId?: string | null;
  percentDone?: number;
  manuallyScheduled?: boolean;
  eventColor?: string;
  inactive?: boolean | null;
  entity: NodeName;
  appearance?: Record<number, SchedulerEventAppearance>;
  isSelectable: boolean;
  isFixed: boolean;
}

export interface NewSchedulerEvent extends SchedulerEvent {
  creationMethod?: string;
}

export interface SchedulerPlaceholderEventData {
  startDate: Date;
  endDate: Date;
  calendarId?: string | null;
  resourceId?: string | null;
  eventColor?: string;
  entity: NodeName;
  dryingBreak?: DryingBreak | null;
  isFixed?: boolean;
  type?: MilestoneType;
}

export enum SchedulerEventColorType {
  TRADE,
  STATUS,
  BASE_ACTUAL,
}

export interface SchedulerResource
  extends Omit<Partial<SchedulerResourceModel>, 'children' | 'parentId' | 'events'> {
  id: string;
  name: string;
  children?: SchedulerResource[];
  parentId?: string | null;
  isTopLevelRow?: boolean;
  isMidLevelRow?: boolean;
  isBottomLevelRow?: boolean;
  sectionIndentation?: number;
}

export interface SchedulerAssignment {
  id: string;
  eventId: string;
  resourceId: string;
}

export interface SchedulerRecurrentTimeRange
  extends Omit<Partial<ResourceTimeRangeModel>, 'resourceId'> {
  id: string;
  recurrentEndDate?: string;
  recurrentStartDate?: string;
  recurrenceRule?: string;
  resourceId?: string;
}

export interface SchedulerCalendarInterval extends Omit<Partial<CalendarIntervalModel>, 'cls'> {
  cls: string;
}

export interface SchedulerCalendar extends Omit<Partial<CalendarModel>, 'intervals'> {
  id: string;
  intervals: SchedulerCalendarInterval[];
}

export interface SchedulerDependency
  extends Omit<Partial<SchedulerDependencyModel>, 'toEvent' | 'fromEvent' | 'lag' | 'type'> {
  id: string;
  to: string;
  toEvent?: SchedulerEvent;
  from: string;
  fromEvent?: SchedulerEvent;
  lag: number;
  type: number;
  fake?: boolean;
}

export enum ClipboardType {
  CUT = 'cut',
  COPY = 'copy',
}

export interface SchedulerClipboardContext extends BaseSchedulerInterval {
  resourceId: string;
}
export interface SchedulerClipboard {
  type: ClipboardType;
  data: string[];
  context: SchedulerClipboardContext | null;
}

export interface ColumnRendererData {
  record: SchedulerResource;
  column: Column;
  row: Row;
  grid: SchedulerPro;
  value: string;
  cellElement?: HTMLDivElement;
  size: {
    configuredHeight: number | null;
  };
}
