// can't use shorthand because vite.config.ts does search and replace
export const BASE_URL = process.env.BASE_URL;
export const NODE_ENV = process.env.NODE_ENV;

// ENVIRONMENT
export const IS_TEST_ENV = process.env.VITE_APP_TEST === 'true';
export const APP_ENV = process.env.VITE_APP_ENV ?? 'dev';
export const IS_DEV_ENV = APP_ENV === 'dev';
export const IS_PROD_ENV = APP_ENV === 'prod';
export const IS_STAGING_ENV = APP_ENV === 'staging';

// APP INFO
export const APP_VERSION = process.env.VITE_APP_VERSION;

// BACKEND API LOCATION
export const GRAPHQL_API_ENDPOINT = `${process.env.VITE_APP_GRAPHQL_API_URL}/api/graphql/v1/`;
export const BULK_API_ENDPOINT = `${process.env.VITE_APP_BULK_API_URL}/v1`;

export const WEBSOCKET_ENDPOINT = `${process.env.VITE_APP_WEBSOCKET_URL}`;

// const bryntumExportServerStaging = 'https://bryntum.staging.internal.koppla.de/';
export const bryntumExportServer = 'https://bryntum.internal.koppla.de/';
// https://eu-central-1.console.aws.amazon.com/systems-manager/parameters/prod/web/bryntum_export_key
export const BRYNTUM_AUTH_TOKEN = 'Zg7hkj25vWTjGHZmWkTr';

// Check if subdomain is for MBN
const subdomain = window.location.host.split('.')?.[0];
export const IS_MBN = subdomain === 'mbn';

// SENTRY
export const SENTRY_DSN =
  'https://8484f0ed4ea121c4187c470717432e5c@o4507057052516352.ingest.de.sentry.io/4507101721919568';
export const SENTRY_DIST = process.env.VITE_APP_SENTRY_DIST;

export const SENTRY_RELEASE = process.env.VITE_APP_SENTRY_RELEASE;

// ANALYTICS
export const USE_PRODUCTION_ANALYTICS = IS_PROD_ENV;

// JIMO
export const JIMO_PROJECT_ID = '0b81abf9-dcfc-4a95-93cc-eb7b1d838630';

// Enables backend timing on query responses. Should only be used for development.
export const ENABLE_SERVER_TIMING = false;
// https://eu-central-1.console.aws.amazon.com/systems-manager/parameters/api/timing-key/description?region=eu-central-1&tab=Table#list_parameter_filters=Name:Contains:timing
export const SERVER_TIMING_KEY = '6EQCtojY4eEUbExu';

export const HIDDEN_MEMBERSHIP_EMAILS = new Set(['support@koppla.de', 'sales@koppla.de']);

export const DEFAULT_PAGE_TITLE = 'koppla';
