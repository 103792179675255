import { IS_PROD_ENV } from '@/utils/config';
import { FirebaseOptions, initializeApp } from 'firebase/app';

const prodConfig: FirebaseOptions = {
  apiKey: 'AIzaSyDhUDz0grSS7hu_zOumkMRi3SwzZm_gouE',
  authDomain: 'api-7703238896555888020-368429.firebaseapp.com',
  databaseURL: 'https://api-7703238896555888020-368429.firebaseio.com',
  projectId: 'api-7703238896555888020-368429',
  storageBucket: 'api-7703238896555888020-368429.appspot.com',
  messagingSenderId: '1065048304126',
  appId: '1:1065048304126:web:3b724d4710bfa91fdc38af',
  measurementId: 'G-DNZCDXKF29',
};

const stagingConfig = {
  apiKey: 'AIzaSyBO25I3oyGyAFwjNKKuxCgG6gg6wdOooM0',
  authDomain: 'koppla-dev.firebaseapp.com',
  projectId: 'koppla-dev',
  storageBucket: 'koppla-dev.appspot.com',
  messagingSenderId: '973804507483',
  appId: '1:973804507483:web:ec66d03a4616b540800a7e',
};

const effectiveConfig = IS_PROD_ENV ? prodConfig : stagingConfig;

export const FIREBASE_API_KEY = effectiveConfig.apiKey!;

export function initializeFirebaseApp(): void {
  initializeApp(effectiveConfig);
}

export function getMicrosoftFirebaseApp() {
  return initializeApp(effectiveConfig, 'microsoft');
}
