import { RouteLocationNormalized } from 'vue-router';

import { getLoginRoute, getProjectsAllOrPickTenantRoute } from '@/router/helpers/redirection';

import { GuardMeta } from './helpers/memberships';

/** Includes functionality that will be called on every route change.
 * Currently only checks authentication and redirects to the specific routes
 */
export function useBeforeEach(
  metaEntities: GuardMeta,
): (to: RouteLocationNormalized, from: RouteLocationNormalized) => Promise<any> {
  return async (to) => {
    const isAuthenticationRequired = to.matched.some(
      (route) => route.meta?.isAuthenticationRequired,
    );

    if (to.meta?.isLogoutRequired) {
      return metaEntities.authenticationService.logout().finally(getLoginRoute);
    }

    const isAuthenticated = await metaEntities.authenticationService
      .isAuthenticated()
      .catch((err) => {
        metaEntities.loggingService.error(err, { code: 'Authentication failed on route change' });
        return null;
      });

    const isVerified = await metaEntities.authenticationService.isVerified().catch((err) => {
      metaEntities.loggingService.error(err, { code: 'Validation failed on route change' });
      return null;
    });

    if (isAuthenticated === null || isVerified === null) return false;

    if (!isAuthenticated) {
      const redirect = isAuthenticationRequired ? getLoginRoute : () => {};
      return redirect();
    }
    /** redirect, if authentication not required, but successful */
    if (!isAuthenticationRequired) {
      return getProjectsAllOrPickTenantRoute();
    }

    return true;
  };
}
