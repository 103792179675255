<template>
  <ThePublicLayout>
    <template #content>
      <div>
        <h1 class="text-white pb-6 tw-text-center">{{ t('misc.browserUnsupported.title') }}</h1>
        <p class="text-white tw-text-center">{{ t('misc.browserUnsupported.message') }}</p>
      </div>
    </template>
  </ThePublicLayout>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';

import ThePublicLayout from '@/layouts/ThePublicLayout.vue';

export default defineComponent({
  components: { ThePublicLayout },
  setup() {
    return { ...useI18n() };
  },
});
</script>
