import { Buffer } from 'buffer';
import { ClientProjectChangeMessage } from 'events.schema';

import { MILLISECONDS_PER_SECOND } from '@/helpers/utils/timeConstants';

import { LocalProjectChangeEvent, RemoteProjectChangeEvent } from '../types';

export function parseIncomingProjectChangeEvent(payload: string): RemoteProjectChangeEvent {
  const event = JSON.parse(payload);
  return {
    ...event,
    operation: {
      ...event.operation,
      input: JSON.parse(Buffer.from(event.operation.input.data).toString()),
    },
  } as RemoteProjectChangeEvent;
}

export function parseOutgoingProjectChangeEvent(
  event: LocalProjectChangeEvent,
): ClientProjectChangeMessage {
  return {
    ...event,
    operation: {
      ...event.operation,
      input: Buffer.from(JSON.stringify(event.operation.input)),
    },
  };
}

export function getConnectionWaitInterval(connectionAttempts: number): number {
  if (connectionAttempts === 0) {
    return 0;
  }

  const minInterval = 500;
  const maxInterval = 30 * MILLISECONDS_PER_SECOND;

  const baseInterval = Math.min(minInterval * 2 ** connectionAttempts, maxInterval);
  const jitter = getJitter();

  return baseInterval + jitter;
}

/**
 * We add a random factor to separate the retries of all clients and avoid collisions.
 * More: https://stackoverflow.com/questions/46939285/why-is-random-jitter-applied-to-back-off-strategies
 */
function getJitter() {
  return Math.random() * MILLISECONDS_PER_SECOND;
}

export function getJitteredTimeout(timeout: number): number {
  return timeout + getJitter();
}
