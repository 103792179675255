import { ApolloQueryResult } from '@apollo/client/core';
import { defineStore } from 'pinia';
import { Ref } from 'vue';

import { useGlobalStore } from '@/common/globalStore';
import { Entity, PartialEntity, ProjectEntity, WrapRef } from '@/common/types';
import { isSupportOrTechSupportUserId } from '@/features/authentication/utils/sanitization';
import { ProjectStore } from '@/features/projects/projectTypes';
import { useRTCController } from '@/features/realTimeCollaboration';
import { OperationInputType } from '@/features/realTimeCollaboration/types';
import {
  CreateProjectMutationVariables,
  ProjectFragment,
  ProjectsForUserQuery,
  ProjectsQuery,
  UpdateProjectMutationVariables,
} from '@/graphql/__generated__/graphql';
import { buildAddress } from '@/helpers/address';
import { useApolloClient } from '@/plugins/apollo';
import { flattenNodeConnection } from '@/repositories/utils/fetchAll';
import { useUserStore } from '@/services/store/user';
import { getOnError } from '@/services/store/utils';
import { StoreEntityNotFoundError } from '@/utils/errors';

import {
  createProjectChangeCompleteSetupEvent,
  createProjectChangeDeleteEvent,
  createProjectChangeUpdateStatusEvent,
} from './projectEvents';
import {
  createProjectMutation,
  fetchAllProjectsQuery,
  fetchProgressQuery,
  fetchProjectQuery,
  fetchProjectsForUserQuery,
  updateProjectMutation,
} from './projectGql';

export const useProjectStore = defineStore(
  'project-store',
  (): WrapRef<
    ProjectStore,
    | 'currentProject'
    | 'fetchAllPromise'
    | 'hasWelcomeMessage'
    | 'isInitialized'
    | 'loading'
    | 'projects'
    | 'projectTenantId'
    | 'showOnlyOwnProjects'
  > => {
    const globalStore = useGlobalStore();

    const fetchAllPromise: Ref<Promise<ProjectEntity[]> | null> = ref(null);
    const hasWelcomeMessage = ref(false);
    const isInitialized = ref(false);
    const loading = ref(false);
    const projects = ref(new Map<string, ProjectEntity>());
    const showOnlyOwnProjects = ref(true);

    const currentProject = ref<ProjectEntity | null>(null);

    watch(
      () => globalStore.currentProjectId,
      async (id) => {
        if (id) {
          currentProject.value = (await fetchProject({ id })) ?? null;
        } else {
          currentProject.value = null;
        }
      },
      { immediate: true },
    );

    const projectTenantId = computed(() => currentProject.value?.tenant.id);

    const parseProjectFragmentIntoEntity = (fragment: ProjectFragment) => ({
      ...fragment,
      address: buildAddress(fragment.address),
      status: fragment.status as ProjectEntity['status'],
    });

    const fetchAll = async (vars?: {
      hasPermissionToSeeAllProjects?: boolean;
      fetchOnlyForUser?: boolean;
    }) => {
      const client = useApolloClient();

      const isSupportUser = isSupportOrTechSupportUserId(useUserStore().ownUser?.id ?? '');

      const _hasPermissionToSeeAllProjects = isSupportUser || vars?.hasPermissionToSeeAllProjects;

      fetchAllPromise.value = client
        .query({
          ...(_hasPermissionToSeeAllProjects
            ? getFetchAllProjectsQueryOptions(Boolean(vars?.fetchOnlyForUser))
            : getFetchUserProjectsQueryOptions()),
          fetchPolicy: 'no-cache',
        })
        .then(
          (result: ApolloQueryResult<ProjectsForUserQuery> | ApolloQueryResult<ProjectsQuery>) => {
            isInitialized.value = true;
            const rawProjects =
              'projects' in result.data
                ? result.data.projects
                : (result as ApolloQueryResult<ProjectsForUserQuery>).data.userProjects;

            const sanitizedProjects = flattenNodeConnection(rawProjects).map((project) =>
              parseProjectFragmentIntoEntity(project),
            );
            projects.value = new Map(sanitizedProjects.map((project) => [project.id, project]));
            return sanitizedProjects;
          },
        );

      return fetchAllPromise.value;
    };

    const getFetchAllProjectsQueryOptions = (hasProjectMembership: boolean) => {
      return {
        query: fetchAllProjectsQuery,
        variables: {
          tenant: globalStore.currentTenantId!,
          orderBy: ['name'],
          hasProjectMembership,
        },
      };
    };

    const getFetchUserProjectsQueryOptions = () => {
      return {
        query: fetchProjectsForUserQuery,
        variables: {
          orderBy: ['name'],
        },
      };
    };

    const fetchProject = async (vars: { id: string }) => {
      const client = useApolloClient();

      if (projects.value.has(vars.id)) return projects.value.get(vars.id) ?? null;

      loading.value = true;

      return client
        .query({
          query: fetchProjectQuery,
          variables: vars,
          fetchPolicy: 'no-cache',
        })
        .then((result) => {
          const { project } = result.data;
          if (!project) return null;
          const parsedProject = parseProjectFragmentIntoEntity(project);
          projects.value.set(project.id, parsedProject);
          return parsedProject;
        })
        .finally(() => {
          loading.value = false;
        });
    };

    const fetchProgress = async (projectId: string, subcontractorId?: string) => {
      const client = useApolloClient();
      const response = await client.query({
        query: fetchProgressQuery,
        variables: { id: projectId, subcontractor: subcontractorId },
        fetchPolicy: 'no-cache',
      });
      return response.data.project?.progress ?? null;
    };

    const createProject = async (variables: Omit<CreateProjectMutationVariables, 'tenant'>) => {
      const client = useApolloClient();

      const input: CreateProjectMutationVariables = {
        ...variables,
        tenant: globalStore.currentTenantId!,
      };

      return client
        .mutate({ mutation: createProjectMutation, variables: input })
        .then((response) => {
          const result = response.data?.createProject?.project ?? null;
          if (!result) return null;
          const parsedResult = parseProjectFragmentIntoEntity(result);
          projects.value.set(result.id, parsedResult);
          return parsedResult;
        });
    };

    const create = async () => {
      throw new Error('project create not on RTC yet, use createProject instead');
    };

    const updateProject = async (vars: UpdateProjectMutationVariables[]) => {
      const oldState: ProjectEntity[] = [];
      const optimisticResult: ProjectEntity[] = [];
      const result = Promise.all(
        vars.map(async (data) => {
          const oldProject = projects.value.get(data.id);
          if (!oldProject) throw new StoreEntityNotFoundError('Project', data.id);
          oldState.push(oldProject);
          const imageUrl =
            !data.image || typeof data.image === 'string'
              ? (data.image as string)
              : URL.createObjectURL(data.image as Blob);
          const project: ProjectEntity = {
            ...oldProject,
            name: data.name,
            internalId: data.internalId,
            address: { ...oldProject.address, ...buildAddress(data) },
            ...(data.image ? { imageUrl } : {}),
            ...(data.status !== null ? { status: data.status as ProjectEntity['status'] } : {}),
          };
          projects.value.set(project.id, project);
          optimisticResult.push(project);

          const client = useApolloClient();

          return client
            .mutate({ mutation: updateProjectMutation, variables: data })
            .then((response) => {
              const projectUpdateResult = response.data?.updateProject?.project ?? null;
              if (!projectUpdateResult) return null;

              updateIfCurrentProject(projectUpdateResult.id, projectUpdateResult);

              const updatedProject = getUpdatedProject(projectUpdateResult.id, projectUpdateResult);

              return updatedProject;
            });
        }),
      ).catch(() => {
        getOnError(projects.value, oldState);
      });

      return {
        optimisticResult,
        result,
      };
    };

    const update = async () => {
      throw new Error('project update not on RTC yet, use updateProject instead');
    };

    const completeSetup = async (input: OperationInputType<'CompleteProjectSetup'>) => {
      const rtcController = useRTCController();

      return rtcController.pushLocalProjectChangeEvent(
        createProjectChangeCompleteSetupEvent(input),
      );
    };

    const finishProject = async () => {
      const rtcController = useRTCController();

      return rtcController.pushLocalProjectChangeEvent(
        createProjectChangeUpdateStatusEvent({
          status: 'COMPLETED',
        }),
      );
    };

    const remove = async () => {
      const rtcController = useRTCController();

      return rtcController.pushLocalProjectChangeEvent(createProjectChangeDeleteEvent());
    };

    const restore = async () => {
      throw new Error('project restore not on RTC yet');
    };

    const getSoftDeletedEntity = () => {
      throw new Error('project getSoftDeletedEntity not on RTC yet');
    };

    const getUpdatedProject = (id: string, change: Partial<ProjectFragment>) => {
      const project = projects.value.get(id);
      let updatedProject: ProjectEntity | null = null;
      if (project) {
        updatedProject = parseProjectFragmentIntoEntity({ ...project, ...change });
        projects.value.set(id, updatedProject);
      }
      return updatedProject;
    };

    const updateIfCurrentProject = (id: string, change: Partial<ProjectFragment>) => {
      if (currentProject.value?.id === id) {
        currentProject.value = parseProjectFragmentIntoEntity({
          ...currentProject.value,
          ...change,
        });
      }
    };

    const updateShowOnlyOwnProjects = (value: boolean) => {
      showOnlyOwnProjects.value = value;
    };

    const showWelcomeMessage = () => {
      hasWelcomeMessage.value = true;
    };

    const hideWelcomeMessage = () => {
      hasWelcomeMessage.value = false;
    };

    const reset = () => {
      projects.value = new Map();
      fetchAllPromise.value = null;
      currentProject.value = null;
    };

    const setState = (state?: Map<string, ProjectEntity>) => {
      if (!state) {
        return;
      }
      projects.value = new Map(state);

      const modifiedCurrentProject = projects.value.get(currentProject.value?.id ?? '');

      if (modifiedCurrentProject) {
        currentProject.value = modifiedCurrentProject;
      }
    };

    const applyChanges = (changes: {
      add?: ProjectEntity[];
      update?: PartialEntity<ProjectEntity>[];
      delete?: Entity[];
    }): void => {
      changes.update?.forEach((project) => {
        updateIfCurrentProject(project.id, project);

        const existingProject = projects.value.get(project.id);
        if (!existingProject) return;
        projects.value.set(existingProject.id, {
          ...existingProject,
          ...project,
        });
      });
      changes.delete?.forEach((project) => {
        if (!projects.value.has(project.id)) return;
        projects.value.delete(project.id);
      });
    };

    const copyState = () => {
      return new Map(projects.value);
    };

    return {
      currentProject,
      fetchAllPromise,
      hasWelcomeMessage,
      isInitialized,
      loading,
      projects,
      projectTenantId,
      showOnlyOwnProjects,
      fetchAll,
      fetchProject,
      fetchProgress,
      createProject,
      create,
      updateProject,
      update,
      completeSetup,
      finishProject,
      delete: remove,
      restore,
      getSoftDeletedEntity,
      updateShowOnlyOwnProjects,
      showWelcomeMessage,
      hideWelcomeMessage,
      reset,
      setState,
      applyChanges,
      copyState,
    };
  },
  {
    persist: {
      pick: ['showOnlyOwnProjects'],
    },
  },
);
